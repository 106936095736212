import React from 'react';
import { Alueet, AlueTarkennukset } from '../Choices';
import { Field } from "formik";
import { Row, Column, Button } from '../util/Flex'


const AlueSivu = props => {
   const queryParameters = new URLSearchParams(window.location.search)
   const alue = queryParameters.get("alue")
   let kartta_url = "kartta_unified.jpg"
   let alueet = Alueet
   if (alue === "pirkanmaa") {
     kartta_url = "kartta_pirkanmaa.png"
	 alueet = alueet.filter(x => x.includes("Pirkanmaa"))
   }
   if (alue === "uusimaa") {
     kartta_url = "kartta_uusimaa.png"
	 alueet = alueet.filter(x => x.includes("Uusimaa"))
   }
   if (alue === "hame") {
     kartta_url = "kartta_hame.png"
	 alueet = alueet.filter(x => x.includes("Häme"))
   }
   return (
	<div className="page">
		<Row>
			<Column>
				<h3>Alueen valinta {alue}</h3>
			</Column>
		</Row>
		<Row>
			<Column>
				<div id="karttacontainer">
					<img className="kartta" src={kartta_url} />
				</div>

				{false && <div id="karttacontainer">
					<img className="kartta" src={'map/kartta.jpeg'} />
					{props.values.area === Alueet[0] && <img className="kartta" src={'map/alue1_glow.png'} />}
					{props.values.area === Alueet[1] && <img className="kartta" src={'map/alue2_glow.png'} />}
					{props.values.area === Alueet[2] && <img className="kartta" src={'map/alue3_glow.png'} />}
				</div>}

			</Column>
			<Column>
			<Field component="div" name="area">
				{alueet.map((alue, i) =>
					<div className="radioElement" key={alue}>
						<input type="radio" className="radioIn" id={"radio" + i} name="area" value={alue} defaultChecked={props.values.area === alue ? true : false} />
						<label htmlFor={"radio" + i} className={props.values.area === alue ? "radiolabel oranger" : "radiolabel"}>
								<strong>{alue}:</strong> {AlueTarkennukset[i]}
						</label>
					</div>
				)}
			</Field>
				<div>
					<Button
						type="button"
						onClick={props.navigateBack}
						disabled={props.pageIndex === 0}
					>
						Edellinen
					</Button>
					<Button
						type="button"
						onClick={props.navigateNext}
						disabled={!(props.values.area)}
					>
						Seuraava
					</Button>
				</div>
			</Column>

		</Row><br /><br />
	</div>
);
}

export default AlueSivu;
