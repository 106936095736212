import React from "react";
import styled from "@emotion/styled";

const Input = styled.input`
  max-width: 100%;
  margin: auto;
`;
const Container = styled.div`
  width: 30%;
  margin: auto;
  margin-top: 20%;
`;

const PasswordGuard = (props) => {
  const [password, setPassword] = React.useState('');
  const pw = 'CO2metsa';
  React.useEffect(() => {
   document.title = "Vaihtolava.com hiililaskuri"
  }, []);
  return (
      <>
        {password !== pw ?
          <Container>
            <label>Syötä salasana käyttääksesi hiililaskuria</label>
            <Input type='password' onChange={e => setPassword(e.target.value)}/>
          </Container>
        :
          <>
          {props.component}
          </>
        }
      </>
  );
}

export default PasswordGuard;
