import React from 'react';
import styled from "@emotion/styled";
import { useField } from "formik";
import { Koot, Palvelut, Alueet, Wastetypes, WastetypesTampere, priceForSelection } from './Choices';
import { FormatPrice,FormatPriceAlv,FormatPriceEur,FormatPriceEurAlv } from './util/NumberFormat.js'

export const MyCheckbox = ({ children, ...props }) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  return (
    <>
      <label className="checkbox">
        <input {...field} {...props} type="checkbox" />
        {children}
      </label>
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const MySelect = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>{label}</StyledLabel>
      <StyledSelect {...field} {...props} />
      {meta.touched && meta.error ? (
        <div class="error">{meta.error}</div>
      ) : null}
    </>
  );
};

export const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div class="error">{meta.error}</div>
      ) : null}
    </>
  );
};


//This price calculator is a mess
export const MyHinta = ({ formikProps, ...props }) => {
  const alue = Alueet.indexOf(formikProps.values.area)
  const palvelu = Palvelut.indexOf(formikProps.values.servicename)
  const koko = Koot.indexOf(formikProps.values.size)
  
  var price, discount
  price = priceForSelection(alue,palvelu,koko)

  if (palvelu === 3) { discount = 25 } else { discount = 0 }

  if (!price) return (<></>)

  formikProps.values.price = price.toFixed(2)
  formikProps.values.discount = discount.toFixed(2)
  formikProps.values.finalprice = (price - discount)
  formikProps.values.finalpricevat = ((price - discount) * 1.255)

  const wasteTypes = Alueet.indexOf(formikProps.values.area) < 3 ? Wastetypes : WastetypesTampere
  var selectedWaste = wasteTypes.find(x => formikProps.values.wastetype === x[0])

  if (!selectedWaste) {
    formikProps.values.wastetype = wasteTypes[0][0]
    selectedWaste = wasteTypes[0][0]
  }
  

  const wastePrice = selectedWaste[1]
  const wasteUnit = selectedWaste[2]

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name}>Hinta</StyledLabel>
      <strong>
        <div style={{ color: "#339966", fontSize: "xx-large" }}>
          {FormatPriceEur(formikProps.values.finalprice)}
        </div>
      </strong>
      <div style={{ color: "#339966" }}>{FormatPriceEurAlv(formikProps.values.finalprice)}</div>
      {discount > 0 ? <div style={{ fontSize: "small" }}>norm. hinta {formikProps.values.price}€, alennus {formikProps.values.discount}€</div> : ""}
      <div style={{ color: "black", fontSize: "normal" }}>
        {wastePrice && wastePrice !== 0 && <>+ jätemaksu {FormatPrice(wastePrice)} €{wasteUnit} ({FormatPriceAlv(wastePrice)} €{wasteUnit} sis. alv)</>}
      </div>
    </>
  );
}

const StyledSelect = styled.select`
`;

const StyledLabel = styled.label`
`;
