export const FormatPrice = (number) => {
    if (!number) return "";
    return number.toLocaleString('fi-FI', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}

export const FormatPriceAlv = (number) => {
    if (!number) return "";
    return FormatPrice(number * 1.255);
}

export const FormatPriceEur = (number) => {
    if (!number) return "";
    return number.toLocaleString('fi-FI', { minimumFractionDigits: 2, maximumFractionDigits: 2, style: 'currency', currency: 'EUR' })
}

export const FormatPriceEurAlv = (number) => {
    if (!number) return "";
    return '(' + FormatPriceEur(number * 1.255) + ' sis.alv)'
}

export const FormatFullEur = (number) => {
    if (!number) return "";
    return FormatPriceEur(number) + ' ' + FormatPriceEurAlv(number)
}

export const FormatFullCustom = (number, label) => {
    if (!number) return "";
    return `${FormatPrice(number)} ${label} (${FormatPriceAlv(number)} ${label} sis.alv)`
}