import React from 'react';
import {Column, Button} from '../util/Flex';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import fiLocale from "date-fns/locale/fi";
import { Field } from "formik";
import { DatePicker } from "@material-ui/pickers";
import {Palvelut} from '../Choices';
import { format } from 'date-fns';
import { FormatPriceEur,FormatPriceEurAlv } from '../util/NumberFormat.js'

const DatePickerField = ({ field, form, ...other }) => {
  React.useEffect(() => {form.validateForm()},[]);
  const currentError = form.errors[field.name];
  const disableWeekends = (date) => {
    return date.getDay() === 0 || date.getDay() === 6;
  }
  const handleChange = (date) => {
		if(date) {
	    date.setHours(12);
	    date.setMinutes(0);
			date.setSeconds(0);
      form.setFieldValue(field.name, date, true);
	  }
  }
  return (
    <>
    <DatePicker
      disablePast
      format="dd.MM.yyyy"
      shouldDisableDate={disableWeekends}
      name={field.name}
      value={form.values[field.name]}
      helperText={currentError}
      error={Boolean(currentError)}
      // if you are using custom validation schema you probably want to pass `true` as third argument
      onChange={d => handleChange(d)}
      {...other}
    />
    {false && form.values[field.name] instanceof Date ? format(form.values[field.name], 'dd.MM.yyyy') : ""}
    </>
  );
};

const ToimitusDatePicker = ({ palvelu, ...props }) => {
  if (palvelu === Palvelut[0] || palvelu === Palvelut[3] )
    return (
			<>
		   <label>Toimituspäivä</label>
      <Field name="date1" component={DatePickerField} />
			</>
    )
  else if (palvelu === Palvelut[1]  || palvelu === Palvelut[2])
    return (<div><label/>valitsemasi palvelu ei sisällä toimitusta</div>)
  else
    return(<></>)
}
const PoishakuDatePicker = ({ palvelu, ...props }) => {
  if (palvelu === Palvelut[1]  || palvelu === Palvelut[2]  || palvelu === Palvelut[3] )
    return (
			<>
		   <label>Poishakupäivä</label>
      <Field name="date2" component={DatePickerField} />
			</>
    )
  else if (palvelu === Palvelut[0] )
    return (<div><label/>valitsemasi palvelu ei sisällä poishakua</div>)
  else
    return(<></>)
}

const ToimitusPoishakuSivu = props => {
	const [specialDelivery, setSpecialDelivery] = React.useState(props.values.specialdelivery !== "");

  const date1 = new Date(props.values.date1);
  const date2 = new Date(props.values.date2);
  const diffTime = date2 - date1;
  const serviceDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const showRent = props.values.servicename === Palvelut[3] && !isNaN(serviceDays) && serviceDays > 3;
  const rent =  5.25 * (serviceDays - 3);

  return (
	<div className="page">
	<Column>
		<h3>Toimitus-/Poishakuajankohta</h3>
		<div className='info'>
			Avolava kolme ensimmäistä vuorokautta veloituksetta,
			jonka jälkeen 5.25€/vrk (6.51 €). Kansilava 157.50€/kk (195.30€).
		</div>
		<div className='info'>Toimitukset arkisin klo. 7-15. Viikonloppujen
			ja pyhäpäivien toimitukset ja pikatoimitukset
			lisähintaan, ks. <a href="hinnasto.pdf">hinnasto</a>.
		 </div>
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fiLocale} timeFormat="24hours">
	 		<ToimitusDatePicker palvelu={props.values.servicename} />
	 		<PoishakuDatePicker palvelu={props.values.servicename} />
    </MuiPickersUtilsProvider>
    {showRent && <div>
        <label>Lavan vuokra ({serviceDays} vrk)</label>
        <strong>
          <div style={{color: "#339966",fontSize: "xx-large"}}>
            {FormatPriceEur(rent)}
          </div>
        </strong>
        <div style={{color: "#339966"}}>
          {FormatPriceEurAlv(rent)}
        </div>
      </div>}
    <div>
      <br/>
      <input name="erikoiskuljetus" type="checkbox" checked={specialDelivery}
              onChange={() => {setSpecialDelivery(!specialDelivery)}} value="asdfgds"/>
      <label style={{"display":"inline", "fontWeight": "normal", "cursor" : "pointer"}} onClick={() => {setSpecialDelivery(!specialDelivery)}}> Minulla on erityistoive toimitusajan suhteen</label>
    </div>
    {specialDelivery && <>
      <label>Toimitusajankohdan lisätieto:</label>
      <Field as="textarea" name="specialdelivery"></Field>
      </>}
		<div>
		<Button
			type="button"
			onClick={props.navigateBack}
			disabled={props.pageIndex === 0}
		>
			Edellinen
		</Button>
		<Button
			type="button"
			onClick={props.navigateNext}
			disabled={props.errors.date1 || props.errors.date2}
		>
			Seuraava
		</Button>
		</div>
		</Column>
	</div>
)};

export default ToimitusPoishakuSivu;
