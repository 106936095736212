import React from 'react';
import { Row, Column } from '../util/Flex'
import { Redirect } from 'react-router-dom';

const AllekirjoitusSivu = props => {
	const [authOptions, setAuthOptions] = React.useState([]);
	const [selectedAuth, setSelectedAuth] = React.useState("");
	const [invitationLink, setInvitationLink] = React.useState("");
	const [invitationId, setInvitationId] = React.useState("");
	const [privateId, setPrivateId] = React.useState("");
	const [error, setError] = React.useState(null);
	const [signState, setSignState] = React.useState("fetching-auth");

	const getLink = () => {
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify({ auth: selectedAuth, orderId: props.orderId })
		};
		fetch('/api/getlink', requestOptions)
			.then(async response => {
				var data = await response.json()
				if (!response.ok) {
					const error = (data && data.message) || response.status;
					return Promise.reject(error);
				}
				return data
			})
			.then(data => {
				console.log(data);
				setInvitationLink(data.invitationLink);
				setInvitationId(data.invitationId);
				setSignState('waiting-signature');
			})
			.catch(error => {
				console.error('There was an error!', error);
				setSignState("waiting-auth-selection")
				setError(error);
			});
	};
	const fetchAuthMethods = () => {
		fetch('/api/authmethods')
			.then(response => response.json())
			.then(data => {
				setAuthOptions(data.methods);
				setSignState("waiting-auth-selection");
				checkCompletion(); //This is hacky, but only needed in case the customer refreshes the sign page after completing the signing
			});
	};
	React.useEffect(() => {
		fetchAuthMethods();
	}, []);

	const checkCompletion = () => {
		fetch('/api/orderstatus/' + props.orderId)
			.then(response => response.json())
			.then(data => {
				if (data.order_status === "signed") {
					setPrivateId(data.private_id);
					setSignState("completed");
				}
			});
	};
	React.useEffect(() => {
		const timer = setInterval(() => {
			checkCompletion();
		}, 5000);
	}, []);
	return (
		<div id="page">
			{error && <h3 style={{ 'color': 'red' }}>Error: {error} !</h3>}
			<Row>
				<Column>
					<h2>Sähköinen allekirjoitus</h2>
				</Column>
			</Row>
			<Row>
				<Column>
					<div className='info'>Viimeistelläksesi tilauksen sinun tulee allekirjoittaa
						tilausehdot sähköisesti. Allekirjoitus tapahtuu Visma Sign -palvelun kautta.
					</div>
					<a href="/../tilausehdot.pdf" target="_blank" rel="noopener noreferrer">
						<h3> 🧾 Tilausehdot.pdf</h3>
					</a>
					{signState === "fetching-auth" && <h4>Haetaan tunnistautumismenetelmiä...</h4>}
					{signState === "waiting-auth-selection" &&
						<div>
							<div className='info'>
								Lue tilausehdot huolellisesti, ja valitse sitten jokin alla näkyvistä allekirjoitustavoista:
							</div>
							{authOptions.map((option, i) =>
								<div key={i}>
									<img
										src={option.image}
										onClick={() => setSelectedAuth(option.identifier)}
										style={option.identifier === selectedAuth ? { 'border': '2px solid orange', 'borderRadius': 60 } : {}}></img>
								</div>)}
							<button type="button" disabled={selectedAuth === ""} onClick={() => { getLink(); setSignState("fetching-link") }}>OK</button>
						</div>
					}
					{signState === "fetching-link" && <h4>Luodaan allekirjoituslinkkiä...</h4>}
					{signState === "waiting-signature" && <a href={invitationLink} target="_blank" rel="noopener noreferrer"><h3>🖊 Siirry allekirjoittamaan tilausvahvistus</h3></a>}
					{signState === "completed" && <Redirect to={"/success/" + privateId} />}
				</Column>
			</Row>
		</div>
	)
};

export default AllekirjoitusSivu;
