
const hetuRegExp = /^(0[1-9]|[12]\d|3[01])(0[1-9]|1[0-2])([5-9]\d\+|\d\d-|[01]\dA)\d{3}[\dA-Z]$/
const tarkistusKirjaimet = ['A', 'B', 'C', 'D', 'E', 'F', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y']

export const testHetu = (hetu) => {
	if (!hetuRegExp.test(hetu)) return false;
	var number = hetu.slice(0, 6) + hetu.slice(7, 10);
	var remainder = parseInt(number) % 31;
	if (remainder >= 10) {
		remainder = tarkistusKirjaimet[remainder - 10];
	} else {
		remainder = remainder.toString()
	}
	return hetu.slice(10, 11) === remainder;
}
export const testPostiNumero = (pNro) => {
	return /\d{5}/.test(pNro)
}

const yTunnusRegExp = /\d{7}-\d/;
const kertoimet = [7, 9, 10, 5, 8, 4, 2];

export const testYTunnus = (tunnus) => {
	if (!yTunnusRegExp.test(tunnus)) return false;
	var number = tunnus.slice(0,7);
	var sum = 0;
	var check = parseInt(tunnus.slice(8,9))
	for (var i = 0; i < number.length; i++) {
		sum += parseInt(number[i]) * kertoimet[i];
	}
	sum %= 11;
	if (sum == 0) return check == 0;
	if (sum == 1) return false;
	return 11 - sum == check;
}
