import React from "react";
import styled from "@emotion/styled";
import { Slider, Typography, withStyles, Paper } from '@material-ui/core';
import { WasteCO2 } from './Choices'
import Chartjs from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';


const Container = styled.div`
  width: 100%;
  margin-top: -10px;
`;

const Stamp = styled.div`
  transform: rotate(-20deg);
  background-image: url('/co2.png');
  background-position: right top;
  background-repeat: no-repeat;
  background-size: 250px 170px;
  width: 250px;
  height: 170px;
  opacity: 0.55;
  position: fixed;
  top: 30px;
  right: 20px;
  pointer-events: none;
  z-index: -100;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 8px;
  @media screen and (min-width: 800px) {
    flex: 1;
  }
`;
const Select = styled.select`
  max-width: 498px;
  margin: 5px;
`;

const Price = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding-left: 5px;
`;

const Prices = styled.div`
  padding-left: 10px;
`;

const TotalText = styled.div`
  font-size: 15px;
  margin-bottom: 0;
  margin-top: auto;
  text-align: center;
  font-weight: 450;
  background-color: #ffffeedd;
  border-radius: 20px;
  padding-bottom: 4px;
`;
const TotalBold = styled.span`
  font-size: 25px;
  color: #fefefe;
  font-weight: 500;
  -webkit-text-stroke: 1.5px #ff6600ee;
  filter: drop-shadow(1px 1px 0px black);
`;
const InfoText = styled.div`
background-color: #ffffeeee;
border-radius: 20px;
  margin-bottom: 30px;
  font-size: 13px;
  padding: 4px;
  padding-left: 30px;
  font-weight: 550;
  margin-top: 10px;
`;
const LogosContainer = styled.div`
  height: 140px;
  margin-bottom: -30px;
  margin-top: -10px;
  width: 100%;
`;

const Logo = styled.div`
  background-image: url('/logo.png');
  height: 100%;
  width: 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  opacity: 0.85;
`;
const LogoCO2 = styled.div`
  background-image: url('/co2.png');
  height: 100%;
  width: 20%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  display: inline-block;
  transform: rotate(25deg);
  position: relative;
  opacity: 0.85;
  top: -18px;
  left: -10px;
`;

const SlidersContainer = styled.div`
`;
const SliderRowContainer = styled.div`
  display: flex;
  width: 100%;
  background-color: ${props => props.index % 2 ? '#ddddccdd' : '#ffffeeee'};
  color:            ${props => props.index % 2 ? '#000000' : '#000000'};
  padding: 3px 8px 3px 8px;
  border-radius: 4px;
`;
const Label = styled.div`
  font-size: 14px;
  margin-top: 3px;
  &:first-letter {
    text-transform: capitalize;
  }
  flex-basis: 70%;
  font-weight: 450;
`;

const VlSlider = withStyles({
  root: {
    display: 'inline-block',
    boxSizing: 'border-box',
    marginLeft: '12px',
    maxWidth: '498px',
    color: ' #ff6600',
    height: 8,
    flexBasis: '75%',
    opacity: 0.95
  },
  thumb: {
    height: 15,
    width: 15,
    backgroundColor: '#f8f8f8',
    border: '2px solid #f74',
    marginTop: -8,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% - 4px)',
  },
  track: {
    marginTop: -4,
    height: 7,
    borderRadius: 4,
  },
  rail: {
    marginTop: -4,
    height: 7,
    borderRadius: 4,
    border: '1px solid orange',
    opacity: 0.5
  },
})(Slider);

const colorsArray = [
  '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'
].map(x => x + 'e0');

const Hiililaskuri = () => {

  const [wasteAmounts, setWasteAmounts] = React.useState(
    WasteCO2.map((x) => ({ name: x.name, amount: 0, co2price: x.co2 }))
  );

  const chartConfig = {
    type: 'pie',
    data: {
      labels: wasteAmounts.map((x) => {
        return x.name + ` (${x.co2price} kg CO₂/tn)`
      }),
      datasets: [{
        label: "kg CO2",
        data: wasteAmounts.map(x => Math.round(x.amount * x.co2price)),
        backgroundColor: colorsArray,
        borderWidth: 0,
        hoverBorderWidth: 2,
        borderColor: '#ffffff'
      }]
    },
    options: {
      cutoutPercentage: 40,
      outerRadius: 40,
      legend: {
        display: true,
        align: 'center',
        position: 'top',
        labels: {
          filter: function (item, chart) {
            return chart.datasets[0].data[item.index];
          }
        }
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, data) => {
            var w = wasteAmounts[tooltipItems.index];
            var value = data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index]
            return [w.name + ' ' + Math.round(value / w.co2price) + 'tn, ' + Math.round(value) + 'kg CO₂'];
          }
        }
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        }
      },
      plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
          color: 'black',
          backgroundColor: '#ffeeeedd',
          borderWidth: 0,
          borderColor: '#333333ee',
          borderRadius: 2,
          clip: true,
          formatter: function (value, context) {
            var w = wasteAmounts[context.dataIndex];
            var value = context.dataset.data[context.dataIndex];
            return [w.name + ', ' + Math.round(value / w.co2price) + 'tn', Math.round(value) + 'kg CO₂'];
          },
          display: (context) => {
            var index = context.dataIndex;
            var value = context.dataset.data[index];
            return value > 0 ? 1 : 0;
          }

        }
      }
    }
  };

  const chartContainer = React.useRef(null);
  const [chartInstance, setChartInstance] = React.useState(null);

  React.useEffect(() => {
    if (chartContainer && chartContainer.current) {
      const newChartInstance = new Chartjs(chartContainer.current, chartConfig);
      setChartInstance(newChartInstance);
    }
  }, [chartContainer]);

  const updateDataset = (datasetIndex, newData) => {
    chartInstance.data.datasets[datasetIndex].data = newData;
    chartInstance.update();
  };


  const handleDataChange = (newValue, wasteName) => {
    var newAmounts = wasteAmounts.map(x => x.name === wasteName ? { name: x.name, amount: newValue, co2price: x.co2price } : x);
    newAmounts[wasteName] = newValue;
    setWasteAmounts(newAmounts);
    updateDataset(0, newAmounts.map(x => x.amount * x.co2price));
  }
  const wasteTotalCO2 = () => {
    return wasteAmounts.reduce((a, b) => { return b.amount * b.co2price + a }, 0).toFixed(0);
  }
  const wasteTotalWeight = () => {
    return wasteAmounts.reduce((a, b) => { return b.amount + a }, 0).toFixed(0);
  }
  Chartjs.Legend.prototype.afterFit = function () { this.height = this.height + 20; }
  return (
    <>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
      <Stamp />
      <Container>
        <Row>
          <Column>
            <LogosContainer><Logo /><LogoCO2 /></LogosContainer>
            <InfoText>
              Hiililaskurilla voit arvioida eri jätetyyppien ja -määrien vaikutusta
              hiilijalanjälkeen. Laskelma perustuu Sorteran toiminnasta kerättyyn tietoon,
              eli se soveltuu nimenomaan Sorteran palveluiden käyttämisestä syntyvän hiilijalanjäljen
              arviointiin.
            </InfoText>
            <SlidersContainer>
              {wasteAmounts.map((wasteData, wasteIndex) => {
                const wasteName = wasteData.name;
                const wastePrice = wasteData.co2price;
                return <SliderRowContainer index={wasteIndex}>
                  <Label>
                    <span
                      style={{
                        backgroundColor: colorsArray[wasteIndex],
                        height: 15,
                        width: 15,
                        display: 'inline-block',
                        border: '0.5px solid white',
                        position: 'relative',
                        top: '1px',
                        marginRight: '10px',
                        borderRadius: 2
                      }} />
                    {wasteName}</Label>
                  <VlSlider
                    onChange={(e, newValue) => { handleDataChange(newValue, wasteName) }}
                    value={wasteAmounts[wasteIndex].amount}
                    valueLabelFormat={(e) => { return e + 'tn' }}
                    valueLabelDisplay="auto"
                    step={1}
                    min={0}
                    max={120}
                  />
                </SliderRowContainer>
              })}
            </SlidersContainer>
          </Column>
          <Column style={{ paddingLeft: 35, paddingBottom: 15, paddingTop: 40 }}>
            <canvas ref={chartContainer} width="100%" height="100%" style={{
              // backgroundImage: "url('co2.png')",
              // backgroundPosition: '36% 56%',
              // backgroundRepeat: 'no-repeat',
              // backgroundSize: '300px'
            }} />
            <TotalText>Yhteensä <TotalBold>{wasteTotalWeight()}tn </TotalBold>
              jätettä, josta vapautuu arviolta <TotalBold>{wasteTotalCO2()}kg </TotalBold>
              hiilidioksidia.</TotalText>
          </Column>
        </Row>
      </Container>
    </>
  );
}

export default Hiililaskuri;
