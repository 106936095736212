import React from "react";
import styled from "@emotion/styled";
import { Slider, Typography, withStyles }  from '@material-ui/core';
import {Wastetypes, WastetypesTampere, Koot, Palvelut, Alueet, UnusualTimes, priceForSelection} from './Choices'

import { FormatPrice,FormatPriceAlv,FormatPriceEur,FormatPriceEurAlv, FormatFullEur, FormatFullCustom } from './util/NumberFormat.js'

const queryParameters = new URLSearchParams(window.location.search)
const alue = queryParameters.get("alue")
let kartta_url = "kartta_unified.jpg"
let alueet = Alueet
if (alue === "pirkanmaa") {
  kartta_url = "kartta_pirkanmaa.png"
  alueet = alueet.filter(x => x.includes("Pirkanmaa"))
}
if (alue === "uusimaa") {
  kartta_url = "kartta_uusimaa.png"
  alueet = alueet.filter(x => x.includes("Uusimaa"))
}
if (alue === "hame") {
  kartta_url = "kartta_hame.png"
  alueet = alueet.filter(x => x.includes("Häme"))
}

const Container = styled.div`
  width: 100%;
  padding-left: 7px;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
const Column = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 8px;
  @media screen and (min-width: 800px) {
    flex: 1;
  }
`;
const DoubleColumn = styled.div`
  max-width: 1110px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 8px;
  @media screen and (min-width: 800px) {
    flex: 2;
  }
`;

const Kartta = styled.img`
  width: 527px;
  margin-top: 20px;
  marginLeft: 7px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  border: 1px solid lightgray;
  border-radius: 1rem;
`;

const Label = styled.div`
  font-size: 20px;
`;

const Select = styled.select`
  max-width: 498px;
  margin: 5px;
`;

const Price = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding-left: 5px;
`;

const Prices = styled.div`
  padding-left: 10px;
`;

const PriceTotal = styled.div`
  font-size: 50px;
  color: white;
  line-height: 58px;
  font-weight: bold;
`;


const PriceTotalVat = styled.div`
  font-size: 18px;
  font-weight: 700;
  line-height: 32px;
`;

const TotalBar = styled.div`
  position: fixed;
  bottom: 0;
  left: 0px;
  width: 100%;
  background-color: var(--green);
  color: white;
  padding: 1rem;
  padding-left: 1.5rem;
  height: 150px;
  border-top: 1px solid #fdfdfd;
`;
const Spacer = styled.div`
  height: 170px;
`;
const Yhteensä = styled.h2`
  margin: 0px;
  font-size: 30px;
  color: white;
`;

const H2 = styled.h2`
  border-bottom: 2px dotted #cccccc;
  padding: 5px;
  margin-left: -7px;
`;

const VlSlider = withStyles({
  root: {

    boxSizing: 'border-box',
    marginLeft: '12px',
    maxWidth: '498px',
    color: 'var(--green)',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const Hintalaskuri = () => {
  const [selectedWaste, setSelectedWaste] = React.useState(["",0,"/tn"]);
  const [size, setSize] = React.useState("");
  const [service, setService] = React.useState("");
  const [area, setArea] = React.useState(alueet[0]);
  const [unusualTimePrice, setUnusualTimePrice] = React.useState(0.0);
  const [wasteAmount, setWasteAmount] = React.useState(0);
  const [serAmount, setSerAmount] = React.useState(0);
  const [tyresAmount, setTyresAmount] = React.useState(0);
  const [rentDays, setRentDays] = React.useState(0);

  const weighingPrice = Alueet.indexOf(area) < 3 ? 50.40 : 30;
  const tyresPrice = 47.25;
  const serPrice = Alueet.indexOf(area) < 3 ? 47.25 : 45;
  const wastetypes = (Alueet.indexOf(area) < 3 ? Wastetypes : WastetypesTampere).filter(x => x[1] !== 0 && x[2] === "/tn"); //filter only prices with weight-based cost

  const totalPrice = () => {
    return  wasteTotal() + weighingPrice + servicePrice() + rentPrice() + unusualTimePrice + discount();
  }

  const wasteTotal = () => {
    return selectedWaste[1]*wasteAmount + tyresAmount*tyresPrice + serAmount * serPrice;
  }

  const rentPrice = () => {
    return Math.max(rentDays - 3,0) * 5.25;
  }

  const servicePrice = () => {
    return priceForSelection(Alueet.indexOf(area),Palvelut.indexOf(service),Koot.indexOf(size));
  }

  const discount = () => {
      return (Palvelut.indexOf(service) === 3) ? -25.0 : 0.0;
  }

  const handleServiceSelectChange = (event, newValue) => {
    setService(event.target.value);
  };

  const handleAreaSelectChange = (event, newValue) => {
    setArea(event.target.value);
  };
  const handleSizeSelectChange = (event, newValue) => {
    setSize(event.target.value);
  };

  const handleWasteSelectChange = (event, newValue) => {
    setSelectedWaste(JSON.parse(event.target.value));
  };

  const handleUnusualTimesSelectChange = (event, newValue) => {
    setUnusualTimePrice(event.target.value*1.00);
  };

  const handleRentDaysChange = (event, newValue) => {
    setRentDays(newValue);
  };

  const handleWasteAmountChange = (event, newValue) => {
    setWasteAmount(newValue);
  };

  const handleSerAmountChange = (event, newValue) => {
    setSerAmount(newValue);
  };

  const handleTyresAmountChange = (event, newValue) => {
    setTyresAmount(newValue);
  };

  React.useEffect(() => {
   document.title = "Sortera.fi hintalaskuri"
  }, []);

  return (
      <>
        <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap" />
        <Container>
          <Row>
            <DoubleColumn>
              <H2>Hintalaskuri</H2>
              <Typography style={{fontFamily: 'Roboto Slab', 'paddingTop' : '17px',}}>Sorteran hintalaskuri auttaa sinua arvioimaan tilattavan palvelun hintaa.</Typography>
              <Typography style={{fontFamily: 'Roboto Slab', 'paddingTop' : '7px',}}>Mikäli tilaat lavan käyttämällä tilauslomakettamme, ja tilaat lavan toimituksen ja poishaun samalla, saat 25€ alennuksen.</Typography>
              <Typography style={{fontFamily: 'Roboto Slab', 'paddingTop' : '7px',}}>Asiakaspalvelumme tavoitat <a href="tel:050 2005">puh. 050 2005</a> tai sähköpostitse <a href="mailto:tilaukset@sortera.fi">tilaukset@sortera.fi</a></Typography>
              <Typography style={{fontFamily: 'Roboto Slab', 'paddingTop' : '7px',}}>
              Tarkemmat hinta- ja palvelutiedot näet hinnastoistamme: <br/>
              {(alue === "uusimaa" || !alue) && <>{" > "}<a href="hinnasto_uusimaa.pdf" target="_blank" rel="noopener noreferrer">Uudenmaan hinnasto</a><br/></>}
              {(alue === "hame" || !alue)  && <>{" > "}<a href="hinnasto_hame.pdf" target="_blank" rel="noopener noreferrer">Hämeen seudun hinnasto</a><br/></>}
              {(alue === "pirkanmaa" || !alue)  && <>{" > "}<a href="hinnasto_pirkanmaa.pdf" target="_blank" rel="noopener noreferrer">Pirkanmaan hinnasto</a></>}
              </Typography>
              <Typography style={{fontFamily: 'Roboto Slab',  fontSize: '1.3rem', fontWeight: 'bold', color: '#f29121', fontStyle:'italic','paddingTop': '1rem'}}>Kaikkiin kuljetushintoihin lisätään polttoainelisä, joka määritetään kuukausittain erikseen. Voimassa oleva polttoainelisän suuruus löytyy uutisosion tiedotteista.</Typography>
  				  </DoubleColumn>
          </Row>
          <Row>
            <Column>
              <H2>Palvelut ja toimitus</H2>
              <Kartta src={kartta_url}/>
              <Label>Alue</Label>
              <Select onChange={handleAreaSelectChange}>
          			<option value="0">--- Valitse alue ---</option>
          			{alueet.map(t => <option key={t} value={t}>{t}</option>)}
              </Select>
              <Label>Palvelu</Label>
              <Select onChange={handleServiceSelectChange}>
          			<option value="0">--- Valitse palvelu ---</option>
          			{Palvelut.map(t => <option key={t} value={t}>{t}</option>)}
              </Select>
              <Label>Lavan koko</Label>
              <Select onChange={handleSizeSelectChange}>
          			<option value="0">--- Valitse koko ---</option>
          			{Koot.map(t => <option key={t} value={t}>{t}</option>)}
              </Select>
              <Label>Erityistoimitusaika</Label>
              <Select onChange={handleUnusualTimesSelectChange}>
          			<option value="0">--- Normaalisti arkisin klo 7-15 ---</option>
          			{UnusualTimes.map(t => <option key={t[0]} value={t[1]}>{t[0] + ': ' + FormatFullEur(t[1])}</option>)}
              </Select>
              <Label>Vuokrapäivät</Label>
              <VlSlider
                onChange={handleRentDaysChange}
                defaultValue={0}
                valueLabelFormat={(e) => {return e + 'pv'}}
                valueLabelDisplay="auto"
                step={1}
                marks = {[{value: 0, label: '0pv',} , {value: 60, label: '60pv',}]}
                min={0}
                max={60}
              />
            </Column>
            <Column style={{'maxWidth' : '30px'}}>
            </Column>
            <Column>
              <H2>Jätteet</H2>
              <Label>Jätetyyppi</Label>
              <Select onChange={handleWasteSelectChange}>
                <option value="0">--- Valitse jätelaji ---</option>
                {wastetypes.map(t => <option key={t[0]} value={JSON.stringify(t)}>{t[0] + ': ' + FormatFullCustom(t[1], '€' + t[2])}</option>)}
              </Select>
              <Label>
                Jätteen nettopaino
              </Label>
              <VlSlider
                onChange={handleWasteAmountChange}
                defaultValue={0}
                valueLabelFormat={(e) => {return e + 'tn'}}
                valueLabelDisplay="auto"
                step={0.1}
                marks = {size === Koot[0] ? [{value: 0,label: '0tn',},{value: 6,label: '6tn',}] : [{value: 0,label: '0tn',},{value: 14,label: '14tn',}]}
                min={0}
                max={size === Koot[0] ? 6 : 14}
              />
              <Label>
                SER-jätteet (tv, atk ja muu elektroniikka) {FormatFullCustom(serPrice, '€/kpl')}
              </Label>
              <VlSlider
                onChange={handleSerAmountChange}
                defaultValue={0}
                valueLabelFormat={(e) => {return e + 'kpl'}}
                valueLabelDisplay="auto"
                step={1}
                marks = {[{value: 0,label: '0kpl',},{value: 10,label: '10kpl',}]}
                min={0}
                max={10}
              />
              <Label>
                Renkaat {FormatFullCustom(tyresPrice, '€/kpl')}
              </Label>
              <VlSlider
                onChange={handleTyresAmountChange}
                defaultValue={0}
                valueLabelFormat={(e) => {return e + 'kpl'}}
                valueLabelDisplay="auto"
                step={1}
                marks = {[{
                          value: 0,
                          label: '0kpl',
                        },
                        {
                          value: 10,
                          label: '10kpl',
                        }]}
                min={0}
                max={10}
              />
              <H2>Hinta-arvio</H2>
              <Prices>
              <Label>Palvelun hinta</Label>
              <Price> {FormatPriceEur(servicePrice())} </Price>
              <Label>Alennus</Label>
              <Price> {FormatPriceEur(discount())} </Price>
              <Label>Lavavuokra</Label>
              <Price> {FormatPriceEur(rentPrice())} </Price>
              <Label>Toimituksen lisähinta</Label>
              <Price> {FormatPriceEur(unusualTimePrice)} </Price>
              <Label>Jäte yhteensä</Label>
              <Price> {FormatPriceEur(wasteTotal())} </Price>
              <Label>Punnitusmaksu</Label>
              <Price> {FormatPriceEur(weighingPrice)} </Price>
              </Prices>
            </Column>
          </Row>
        </Container>
        <Spacer/>
        <TotalBar>
          <Yhteensä>Hinta yhteensä:</Yhteensä>
          <PriceTotal>{FormatPriceEur(totalPrice())}</PriceTotal>
          <PriceTotalVat>{FormatPriceEurAlv(totalPrice())}</PriceTotalVat>
        </TotalBar>
      </>
  );
}

export default Hintalaskuri;
