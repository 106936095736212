import React from 'react';
import { Row, Column } from '../util/Flex'

const Success = props => {
	const [error, setError] = React.useState(null);
	const [success, setSuccess] = React.useState(false);

	React.useEffect(() => {
		fetch('/api/send_order/' + props.private_id)
			.then(async response => {
				var data = await response.json()
				if (!response.ok) {
					const error = (data && data.message) || response.status;
					return Promise.reject(error);
				}
				return data;
			})
			.then(data => {
				console.log(data);
				setSuccess(true);
				document.title = 'Vaihtolavan tilauslomake - Tilaus lähetetty onnistuneesti!';
				window.gtag('event','lava_tilattu')
			   
			})
			.catch(error => {
				console.error('There was an error!', error);
				setError(error);
				document.title = 'Vaihtolavan tilauslomake - Tilauksen luominen epäonnistui!';
			});
	}, [props.private_id]);
	return (
		<div id="page">
			<Row>
				<Column>
					{error &&
						<>
							<h3 style={{ 'color': 'red' }}>Error: {error} !</h3>
							<div className='info'>
								Valitettavasti tilauksen luominen epäonnistui.
							</div>
						</>
					}
					{success && !props.fromVisma &&
						<>
							<h2>✅ Tilaus lähetetty onnistuneesti!</h2>
							<div className='info'>
								Tilauksenne käsitellään mahdollisimman nopeasti.
							</div>
						</>
					}
					<strong>
						<a href="https://sortera.fi">Takaisin sortera.fi etusivulle</a>
					</strong>
				</Column>
			</Row>
		</div>
	)
};

export default Success;
