import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import AlueSivu from './sivut/AlueSivu';
import KokoSivu from './sivut/KokoSivu';
import PalveluSivu from './sivut/PalveluSivu';
import AsiakastyyppiSivu from './sivut/AsiakastyyppiSivu';
import YhteystietoSivu from './sivut/YhteystietoSivu';
import ToimitusPoishakuSivu from './sivut/ToimitusPoishakuSivu';
import LaskuSivu from './sivut/LaskuSivu';
import { testHetu, testYTunnus, testPostiNumero } from './util/Tests';
import Wiz from './Wiz';
import { Koot, Palvelut, Alueet, Wastetypes, WastetypesTampere } from './Choices';
import { Redirect } from 'react-router-dom';
import { Button } from './util/Flex'
import { Slider, Typography, withStyles } from '@material-ui/core';

const DEBUG = false;

const today = new Date();
today.setHours(0, 0, 0, 0);
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/


var debugValues = {
	"name": "Toivo Testaaja",
	"phone": "1234567",
	"email": "mikko.k.tamminen@gmail.com",
	"date1": "2026-08-12T09:00:00.000Z",
	"date2": "2026-08-14T09:00:00.000Z",
	"acceptedTerms": true,
	"area": "Uusimaa 2",
	"customertype": "1",
	"company": "Vaihtolavacom",
	"companyid": "0234295-2",
	"servicename": "Lavan toimitus ja poishaku",
	"size": "Iso auto 16 – 20m³",
	"address": "katu 444",
	"postalcode": "12334",
	"city": "KaupunkiXZ", 
	"zip": "",
	"price": "180",
	"discount": "0",
	"finalprice": "180",
	"finalpricevat": "223.2",
	"discount_code": "messut tampere",
	"moreinfo": "Lisätietoja",
	"custominvoice_enabled": "0",
	"custominvoice": "",

	"invoicing_address": "Laskutusosoite",
	"invoicing_postalcode": "333333",
	"invoicing_city": "Laskutuskaupunki",

	"wastetype": "Rakennusjäte",
	"separate_contactperson": "0",
	"contactperson_name": "",
	"contactperson_phone": "",
	"housingcompanycode": "0234295-2",
	"pin": "231280-9294",
	"specialdelievery": ""
};

var initialValues = {
	name: "",
	phone: "",
	email: "",
	date1: "", //Toimituspäivä
	date2: "", //Poishakupäivä
	acceptedTerms: false, // added for our checkbox
	area: "", // Alue
	customertype: "1", // 1=yksityinen, 2=yritys
	company: "",
	companyid: "",
	servicename: "", // Palvelu
	size: "Iso auto 16 – 20m³", // Koko
	address: "",
	postalcode: "",
	city: "",
	zip: "",

	price: "0",
	discount: "0",
	finalprice: "0",
	finalpricevat: "0",

	moreinfo: "",
	discount_code: "",
	custominvoice_enabled: "1",
	custominvoice: "",

	invoicing_address: "",
	invoicing_postalcode: "",
	invoicing_city: "",

	wastetype: "",
	separate_contactperson: "0",
	contactperson_name: "",
	contactperson_phone: "",
	housingcompanycode: "",
	specialdelievery: ""
};

if (localStorage.getItem('formValues') != null) {
	initialValues = JSON.parse(localStorage.getItem('formValues'));
	initialValues.pin = "";
	initialValues.discount_code = "";
}

if (DEBUG) {
	initialValues = debugValues;
}

const queryParameters = new URLSearchParams(window.location.search)
const alue = queryParameters.get("alue")
const cookieConsent = queryParameters.get("analytics")

if (cookieConsent === "yes") {
	if (alue === "pirkanmaa") {
		window.gtag('config', 'G-HFGJN4LN80')
	}
	if (alue === "uusimaa") {
		window.gtag('config', 'G-HDQCYWJJDF')
	}
	if (alue === "hame") {
		window.gtag('config', 'G-BS16R8XLHG')
	}
	if (alue === null) {
		window.gtag('config', 'G-HDQCYWJJDF')
	}
}
class LavaLomake extends Component {
	render() {
		return (
			<Wiz pages={[AsiakastyyppiSivu, AlueSivu, PalveluSivu, KokoSivu, ToimitusPoishakuSivu, YhteystietoSivu, LaskuSivu]}>
				{wizProps => (
					<div>
						<Formik
							initialValues={initialValues}
							validationSchema={Yup.object().shape({
								name: Yup.string()
									.required("Pakollinen kenttä"),
								phone: Yup.string()
									.required("Pakollinen kenttä")
									.matches(phoneRegExp, 'Puhelinnumero ei täytä vaatimuksia'),
								email: Yup.string()
									.email("Sähköpostiosoite ei täytä vaatimuksia")
									.required("Pakollinen kenttä"),
								company: Yup.string().when('customertype', { is: "2", then: Yup.string()
								  .required("Yrityksen nimi vaaditaan")
									}),
								companyid: Yup.string()
								  .when('customertype', { is: "2", then: Yup.string()
										.required("Y-tunnus vaaditaan")
										.test('YTunnusTest',
											'Y-tunnus tulee olla muodossa 1234567-8',
											value => testYTunnus(value)
									  )
									}),
								date1: Yup.date("Kentän täytyy olla päivämäärä")
									.when(['servicename', 'date2'], { is: (s, d) => s === Palvelut[3] && d instanceof Date, then: Yup.date().required("Päivämäärä vaaditaan").max(Yup.ref('date2'), 'Toimituspäivä ei voi olla hakupäivän jälkeen') })
									.when('servicename', { is: Palvelut[0], then: Yup.date().required("Päivämäärä vaaditaan").min(today, 'Päivämäärä ei voi olla menneisyydessä') })
									.when('servicename', { is: Palvelut[3], then: Yup.date().required("Päivämäärä vaaditaan").min(today, 'Päivämäärä ei voi olla menneisyydessä') }),
								date2: Yup.date("Kentän täytyy olla päivämäärä")
									.when('servicename', { is: Palvelut[1], then: Yup.date().required("Päivämäärä vaaditaan").min(today, 'Päivämäärä ei voi olla menneisyydessä') })
									.when('servicename', { is: Palvelut[2], then: Yup.date().required("Päivämäärä vaaditaan").min(today, 'Päivämäärä ei voi olla menneisyydessä') })
									.when('servicename', { is: Palvelut[3], then: Yup.date().required("Päivämäärä vaaditaan").min(today, 'Päivämäärä ei voi olla menneisyydessä') }),
								acceptedTerms: Yup.boolean()
									.required("Pakollinen kenttä")
									.oneOf([true], "Sinun täytyy hyväksyä käyttöehdot"),
								area: Yup.string()
									.oneOf(
										Alueet,
										"Valitse alue"
									).required("Pakollinen kenttä"),
								wastetype: Yup.string()
									.oneOf(
										[...Wastetypes, ...WastetypesTampere].map(t => t[0]),
										"Valitse jätelaji"
									).required("Pakollinen kenttä"),
								servicename: Yup.string()
									.oneOf(
										Palvelut,
										"Valitse palvelu"
									).required("Pakollinen kenttä"),
								size: Yup.string()
									.oneOf(
										Koot,
										"Valitse koko"
									).required("Pakollinen kenttä"),
								address: Yup.string()
									.required("Pakollinen kenttä"),
								pin: Yup.string().when('customertype', {
									is: "1", then:
										Yup.string().test('Hetutest',
											'Hetu virheellistä muotoa',
											value => testHetu(value)
										)
								}),
								postalcode: Yup.string().required("Pakollinen kenttä").test('PNroTest',
									'Postinumero virheellistä muotoa',
									value => testPostiNumero(value)
								),
								city: Yup.string().required("Pakollinen kenttä"),
								// zip: Yup.string().required("Pakollinen kenttä"),
								housingcompanycode: Yup.string()
								  .when('customertype', { is: "3", then: Yup.string()
									  .required("Asukasyhtiön Y-tunnus vaaditaan")
										.test('YTunnusTest',
											'Y-tunnus tulee olla muodossa 1234567-8',
											value => testYTunnus(value)
										)
									}),
								contactperson_name: Yup.string().when('separate_contactperson', { is: "1", then: Yup.string().required("Toimituksen yhteyshenkilön nimi vaaditaan") }),
								contactperson_phone: Yup.string().when('separate_contactperson', { is: "1", then: Yup.string().required("Toimituksen yhteyshenkilön puhelinnumero vaaditaan").matches(phoneRegExp, 'Puhelinnumero ei täytä vaatimuksia') }),
							})}
							onSubmit={(values, { setSubmitting, setStatus }) => {
								var valuesCopy = JSON.parse(JSON.stringify(values));
								if (valuesCopy.specialdelievery !== "")
									valuesCopy.moreinfo = valuesCopy.moreinfo + "\nErityisaika: " + valuesCopy.specialdelievery;
								if (valuesCopy.discount_code !== "")
									valuesCopy.moreinfo = valuesCopy.moreinfo + "\nAlennuskoodi: " + valuesCopy.discount_code;
								valuesCopy.zip = `${values.postalcode} ${values.city}`
								valuesCopy.specialdelievery = undefined;
								valuesCopy.postalcode = undefined;
								valuesCopy.city = undefined;
								const requestOptions = {
									method: 'POST',
									headers: { 'Content-Type': 'application/json' },
									body: JSON.stringify(valuesCopy, null, 2)
								};
								fetch('/handleform', requestOptions)
									.then(async response => {
										const data = await response;
										if (!response.ok) {
											const error = (data && data.message) || response.status;
											return Promise.reject(error);
										}
										return data.json()
									})
									.then(data => {
										if (data.status === "OK") {
											setStatus({ redirect: '/success/' + data.id });
										} else if (data.status === "SIGN") {
											setStatus({ redirect: '/sign/' + data.id });
										}
									})
									.catch(error => {
										setStatus({ success: false });
										setStatus({ errormsg: error.toString() });
										setSubmitting(false);
									});
							}}
						>
							{props => {
								localStorage.setItem('formValues1', JSON.stringify(props.values));
								return (
									<form onSubmit={props.handleSubmit}>
										{props.status && props.status.errormsg &&
											<div>
												<h2>Jokin meni pieleen!</h2>
												<div>Pahoittelemme tapahtunutta. Voit tavoittaa meidät puh. 050 2005 tai
													tilaukset@sortera.fi</div>
												<a href="https://sortera.fi">sortera.fi</a>
												<small>{props.status.errormsg}</small>
											</div>}
										{props.isSubmitting &&
											<h2>Tilausta lähetetään...</h2>
										}
										{(!props.status || !(props.status.success || props.status.errormsg)) && !props.isSubmitting && wizProps.renderPage(props)}
										{DEBUG &&
											<Button
												type="submit"
											>
												Lähetä tilaus
											</Button>}
										{props.status && props.status.redirect && <Redirect to={props.status.redirect} />}
									    {DEBUG && <pre>{JSON.stringify(Object.keys(props.values).reduce((obj, key) => {
									        if (key.includes('invoic')) {
									            obj[key] = props.values[key];
									        }
									        return obj;
									 	}, {}), null, 2)}</pre>}
									</form>
								);
							}}
						</Formik>
						<Typography style={{ fontSize: '13pt', fontWeight: 'bold', color: '#f29121', fontStyle:'italic','paddingTop': '1rem', 'paddingLeft': '7px'}}>Kaikkiin kuljetushintoihin lisätään polttoainelisä, joka määritetään kuukausittain erikseen. Voimassa oleva polttoainelisän suuruus löytyy uutisosion tiedotteista.</Typography>
					</div>
				)}
			</Wiz>
		);
	}
}

export default LavaLomake;
