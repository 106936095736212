export const Alueet = [
  "Uusimaa 1",
  "Uusimaa 2",
  "Häme 1",
  "Häme 2",
  "Pirkanmaa 1",
  "Pirkanmaa 2"
]

export const AlueTarkennukset = [
  "Helsinki, Espoo, Vantaa, Kauniainen, Sipoo, Kirkkonummi, Vihti, Nurmijärvi, Tuusula, Järvenpää, Kerava.",
  "Hanko, Tammisaari, Porvoo, Inkoo, Siuntio, Lohja, Hyvinkää, Mäntsälä, Pornainen, Pukkila, Askola, Myrskylä, Lapinjärvi, Loviisa",
  "Asikkala, Heinola, Hollola, Lahti, Iitti, Orimattila",
  "Karkkila, Loppi, Hattula, Hämeenlinna, Janakkala, Riihimäki, Hausjärvi, Kärkölä",
  "Tampere, Pirkkala, Lempäälä, Ylöjärvi, Hämeenkyrö, Nokia, Vesilahti, Kangasala, Orivesi, Sastamala, Valkeakoski",
  "Ikaalinen, Parkano, Kihniö, Virrat, Ruovesi, Mänttä-Vilppula, Juupajoki, Pälkäne, Punkalaidun, Akaa, Urjala"
]

export const Palvelut = [
  "Lavan toimitus",
  "Lavan välityhjennys",
  "Lavan poishaku ja tyhjennys",
  "Lavan toimitus ja poishaku"
]

export const Koot = [
  "Pieni auto 6-14 m³",
  "Iso auto 16 – 20m³"
]

export const Wastetypes = [
  [ "Asbesti", 260.00, "/tn" ],
  [ "Asfalttikuorma, iso auto*", 210.00, "/krt" ],
  [ "Asfalttikuorma, pieni auto*", 157.50, "/krt" ],
  [ "Betoni- ja tiilijäte (alle 1 m x1 m x1 m)", 57.75, "/tn" ],
  [ "Bitumijäte", 228.90, "/tn" ],
  [ "Energiajäte (puhdas, kuiva)", 126.00, "/tn" ],
  [ "Kaakelijäte, uusiokäyttöön (puhdas)", 78.75, "/tn" ],
  [ "Kannot", 31.50, "/tn" ],
  [ "Kipsijäte, uusiokäyttöön (puhdas, kuiva)", 94.50, "/tn" ],
  [ "Kiristekalvomuovi, uusiokäyttöön (puhdas)", 0, "/tn" ],
  [ "Kokonaiset kuormalavat", 0, "/kpl" ],
  [ "Kyllästetty puujäte", 313.95, "/tn" ],
  [ "Lasi", 138.60, "/tn" ],
  [ "Maa-aines (epäpuhdas)", 66.15, "/tn" ],
  [ "Maa-aines (puhdas) maankaatomaksu, iso auto*", 157.50, "/krt" ],
  [ "Maa-aines (puhdas) maankaatomaksu, pieni auto*", 105.00, "/krt" ],
  [ "Metalli**", 0, "/tn" ],
  [ "Pahvi, uusiokäyttöön (puhdas, kuiva)", 0, "/tn" ],
  [ "Posliini, uusiokäyttöön (puhdas)", 94.50, "/tn" ],
  [ "Puutarhajäte / Haravointijäte", 126.00, "/tn" ],
  [ "Rakennusjäte", 176.40, "/tn" ],
  [ "Rakennusjäte lk.2", 228.90, "/tn" ],
  [ "Risut", 52.50, "/tn" ],
  [ "Sekalainen puujäte", 52.50, "/tn" ],
  [ "SER-jäte", 262.25, "/tn" ],
  [ "SER-jäte / kpl", 47.25, "/kpl" ],
  [ "Talkoojäte", 176.40, "/tn" ],
  [ "Villajäte, uusiokäyttöön (puhdas, kuiva)", 176.40, "/tn" ],
]

export const WastetypesTampere = [
  [ "Betoni- ja tiilijäte (max 1 m x 1 m x 1 m)", 45.00, "/tn" ],
  [ "Energiajäte (puhdas, kuiva)", 126.00, "/tn" ],
  [ "Kaakelijäte, uusiokäyttöön (puhdas)", 78.75, "/tn" ],
  [ "Kipsijäte, uusiokäyttöön (puhdas, kuiva)", 94.50, "/tn" ],
  [ "Kiristekalvomuovi, uusiokäyttöön (puhdas)", 0, "/tn" ],
  [ "Kokonaiset kuormalavat", 0, "/kpl" ],
  [ "Kyllästetty puujäte", 313.95, "/tn" ],
  [ "Epäpuhtaat maa- ja kiviainekset (epäpuhdas)", 100.00, "/tn" ],
  [ "Maa-aines (puhdas) maankaatomaksu, iso auto*", 157.50, "/krt" ],
  [ "Maa-aines (puhdas) maankaatomaksu, pieni auto*", 105.00, "/krt" ],
  [ "Metalli**", 0, "/tn" ],
  [ "Pahvi, uusiokäyttöön (puhdas, kuiva)", 0, "/tn" ],
  [ "Posliini, uusiokäyttöön (puhdas)", 94.50, "/tn" ],
  [ "Rakennusjäte", 180.00, "/tn" ],
  [ "Rakennusjäte lk.2", 180.00, "/tn" ],
  [ "Sekalainen puujäte", 45.00, "/tn" ],
  [ "SER-jäte", 262.25, "/tn" ],
  [ "SER-jäte / kpl", 45.00, "/kpl" ],
  [ "Talkoojäte", 180.00, "/tn" ],
  [ "Villajäte, hyötykäyttöön (puhdas, kuiva)", 180.00, "/tn" ],
  [ "Risut", 60.00, "/tn" ],
  [ "Kannot", 90.00, "/tn" ],
  [ "Haravointijäte", 126.00, "/tn" ],
]

export const WasteCO2 = [
  {name: "Betoni- ja tiilijäte",co2: "16.305"},
  {name: "Kipsijäte",co2: "9.014"},
  {name: "Haravointijäte",co2: "2.187"},
  {name: "Puujäte (puhdas, kyllästämätön)",co2: "9.903"},
  {name: "Rakennusjäte, luokka I",co2: "815.936"},
  {name: "Rakennusjäte, luokka II",co2: "555.640"},
  {name: "Asfaltti",co2: "12.024"},
  {name: "Sekajäte",co2: "406.328"},
  {name: "Biojäte",co2: "3.521"},
  {name: "Metalliromu",co2: "137.251"},
  {name: "Pakkaus- ja energiajäte",co2: "1688.829"},
  {name: "Katujen puhdistusjäte",co2: "0.582"},
  {name: "SER-romu",co2: "707.133"},
  {name: "Autonrenkaat",co2: "850.222"},
  {name: "Vaaralliset jätteet",co2: "1423.153"},
  {name: "Ylijäämämaa-aines",co2: "1.224"},
  {name: "Louhe/ylijäämä kiviaines",co2: "0.783"},
  {name: "Muovi",co2: "1139.500"}
]

export const UnusualTimes = [
  ["Ma-pe erityisaikaan", 94.5],
  ["Lauantaisin", 157.5],
  ["Sunnuntaisin", 262.5],
  // ["Pikatoimitus arkisin (2h)", 105]
]


//Alue, palvelu, koko
export const priceForSelection = (alue, palvelu, koko) => {
  var price = 0;
  if (alue === 0 || alue === 1 || alue === 2 || alue === 3) {
    if ( palvelu === 0 && koko === 0 ) { price = 63 }
    if ( palvelu === 0 && koko === 1 ) { price = 73.5 }
    if ( palvelu === 1 && koko === 0 ) { price = 147 }
    if ( palvelu === 1 && koko === 1 ) { price = 168 }
    if ( palvelu === 2 && koko === 0 ) { price = 147 }
    if ( palvelu === 2 && koko === 1 ) { price = 168 }
    if ( palvelu === 3 && koko === 0 ) { price = 210 }
    if ( palvelu === 3 && koko === 1 ) { price = 241.5 }
    if ( alue === 1 || alue === 3 ) { price *= 1.2 }
  }
  if ( alue === 4 ) {
    if ( palvelu === 0 && koko === 0 ) { price = 60 }
    if ( palvelu === 0 && koko === 1 ) { price = 80 }
    if ( palvelu === 1 && koko === 0 ) { price = 120 }
    if ( palvelu === 1 && koko === 1 ) { price = 140 }
    if ( palvelu === 2 && koko === 0 ) { price = 120 }
    if ( palvelu === 2 && koko === 1 ) { price = 140 }
    if ( palvelu === 3 && koko === 0 ) { price = 180 }
    if ( palvelu === 3 && koko === 1 ) { price = 220 }
   }
   if ( alue === 5 ) {
     if ( palvelu === 0 && koko === 0 ) { price = 80 }
     if ( palvelu === 0 && koko === 1 ) { price = 100 }
     if ( palvelu === 1 && koko === 0 ) { price = 150 }
     if ( palvelu === 1 && koko === 1 ) { price = 175 }
     if ( palvelu === 2 && koko === 0 ) { price = 150 }
     if ( palvelu === 2 && koko === 1 ) { price = 175 }
     if ( palvelu === 3 && koko === 0 ) { price = 230 }
     if ( palvelu === 3 && koko === 1 ) { price = 275 }
  }
  return price;
}
