import React from 'react';
import {Palvelut} from '../Choices';
import {MySelect} from '../Components';
import {Row, Column, Button} from '../util/Flex'

const PalveluSivu = props => (
	<div className="page">
	<Row>
	<Column>
	<h3>Palvelun valinta</h3>
	</Column>
	</Row>
    <Column>
		<MySelect label="Valitse palvelu" name="servicename">
			<option value=""></option>
			<option value={Palvelut[0]}>{Palvelut[0]}</option>
			<option value={Palvelut[1]}>{Palvelut[1]}</option>
			<option value={Palvelut[2]}>{Palvelut[2]}</option>
			<option value={Palvelut[3]}>{Palvelut[3]}</option>
		</MySelect>
		<div className='info stronger'>
		  Kun tilaat lavan toimituksen ja poishaun samalla, saat 25€ alennuksen!
		 </div>
		 <div>
		<Button
			type="button"
			onClick={props.navigateBack}
			disabled={props.pageIndex === 0}
		>
			Edellinen
		</Button>
		<Button
			type="button"
			onClick={props.navigateNext}
			disabled={!(props.values.servicename)}
		>
			Seuraava
		</Button>
		</div>

		</Column>
	</div>
);

export default PalveluSivu;
