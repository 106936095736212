import React from 'react';
import { Koot, Wastetypes, WastetypesTampere, Alueet } from '../Choices';
import { MySelect, MyHinta } from '../Components';
import { Field } from "formik";
import { Row, Column, Button } from '../util/Flex'

// const queryParameters = new URLSearchParams(window.location.search);
// const alue = queryParameters.get("alue");

const KokoSivu = props => {

	var koot = Koot
	if (["Häme 1", "Häme 2"].includes(props.values.area)) {
		 koot = [Koot[1]];
	}
	var wasteTypes = Wastetypes;
	if (Alueet.indexOf(props.values.area) > 2) {
		wasteTypes = WastetypesTampere;
	}

	React.useEffect(() => {
		props.setFieldValue('size', koot[0]);
		props.setFieldValue('wastetype', wasteTypes[0]);
	}, []);

	return (<div className="page">

		<Row>
			<Column>
				<h3>Lavan koko ja jätetyyppi</h3>
			</Column>
		</Row>
		<Row>

			<Column>
				<Field component="div" name="size">
				    { !["Häme 1", "Häme 2"].includes(props.values.area) && <div className="radioElement">
						<input type="radio" id="radioOne" name="size" value={Koot[0]} checked={props.values.size === Koot[0] ? true : false} />
						<label htmlFor="radioOne" className="radiolabel">
							<div className={props.values.size === Koot[0] ? "oranger" : ""}>
								<label className='sizelabel'>Pieni auto</label>
								<div className="kokokuva" >
									<img alt="pikkulava" style={{ 'maxWidth': '100%' }} src={'pikkulava.png'} />
								</div>
								<ul>
									<li>Maksimilavapaino 6t</li>
									<li>Lavojen korkeudet yli 1m ja alle 1m</li>
								</ul>
							</div></label>
					</div> }
					<div className="radioElement">
						<input type="radio" id="radioTwo" name="size" value={Koot[1]} checked={props.values.size === Koot[1] ? true : false} />
						<label htmlFor="radioTwo" className="radiolabel">
							<div className={props.values.size === Koot[1] ? "oranger" : ""}>
								<label className='sizelabel'>Iso auto</label>
								<div className="kokokuva" >
									<img alt="isolava" style={{ 'maxWidth': '100%' }} src={'isolava.png'} />
								</div>
								<ul>
									<li>Maksimilavapaino 14t</li>
									<li>Lavojen korkeudet alkaen 1.2m</li>
								</ul>
							</div></label>
					</div>
				</Field>
			</Column>
			<Column>
				<div className='info'>
					Pienen auton lavat voidaan toimittaa ahtaisiin paikkoihin. Lavan tunnistaa
					vetolenkkikorkeudesta (kuvassa keltaisella), joka on noin
					aikuisen ihmisen vyötärön korkeudella
				</div>
				<div className='info'>
					Lavoja on avo- ja kansilavoina. Avolavoista emme peri vuokraa ensimmäiseen
					kolmeen päivään. Kansilavoista veloitamme päivävuokraa kaikilta päiviltä.
				</div>
				<div className='info'>
					Toimitamme tilauksesta myös 1m3 kokoisia suursäkkejä
					rakennus- ja talkoojätteiden keräämiseksi
				</div>
				<div className='info stronger'>
					Avolava kolme ensimmäistä vuorokautta veloituksetta,
					jonka jälkeen 5,25 €/vrk (6,51 €/vrk sis.alv). Kansilava 157,50 €/kk (195,30 €/kk sis.alv).
				</div>
				<div className='info stronger oranger'>
					Mikäli kyseessä on erikoisempi lokaatio, jossa voi olla normaalista
					poikkeava tai matalampi kohde, niin voimme tulla paikan päälle katsomaan
					olisiko toteuttaminen kuitenkin mahdollista.
				</div>
				<MySelect label="Lavan koko" name="size">
					<option value=""></option>
					{koot.map(t => <option key={t} value={t}>{t}</option>)}
				</MySelect>
				<MySelect label="Jätetyyppi" name="wastetype">
					{wasteTypes.map(t => <option key={t[0]} value={t[0]}>{t[0]}</option>)}
				</MySelect>
				<div className='info'>
					{props.values.area.includes('Uusimaa') &&
							<>Tarkempaa tietoa jäteluokista löydät <a href="hinnasto_uusimaa.pdf" target="_blank" rel="noopener noreferrer">hinnastosta.</a></>}
					{props.values.area.includes('Pirkanmaa') &&
							<>Tarkempaa tietoa jäteluokista löydät <a href="hinnasto_pirkanmaa.pdf" target="_blank" rel="noopener noreferrer">hinnastosta.</a></>}
					{props.values.area.includes('Häme') &&
							<>Tarkempaa tietoa jäteluokista löydät <a href="hinnasto_hame.pdf" target="_blank" rel="noopener noreferrer">hinnastosta.</a></>}

				</div>

				<MyHinta formikProps={props}></MyHinta>
				<div>
					<Button
						type="button"
						onClick={props.navigateBack}
						disabled={props.pageIndex === 0}
					>
						Edellinen
					</Button>
					<Button
						type="button"
						onClick={props.navigateNext}
						disabled={props.errors.size || props.errors.wastetype}
					>
						Seuraava
					</Button>
				</div>
			</Column>
		</Row>
	</div>
);
}

export default KokoSivu;
