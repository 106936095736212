import React from 'react';
import {MySelect} from '../Components';
import {Row, Column, Button} from '../util/Flex'

const AsiakastyyppiSivu = props => {
	
	const queryParameters = new URLSearchParams(window.location.search)
	const alue = queryParameters.get("alue")

	return (
	<div id="page">
	<Row>
	<Column>
	<h2>Vaihtolavan tilaus</h2>
	</Column>
	</Row>
	<Row>
	  <Column>
		<div className='info'>Tervetuloa Sorteran tilauslomakkeeseen!
		Lomake kysyy sinulta tilaukseen tarvittavat tiedot.
		Tarkemmat hinta- ja palvelutiedot näet hinnastoistamme: <br/>
		{(alue === "uusimaa" || !alue) && <>{" > "}<a href="hinnasto_uusimaa.pdf" target="_blank" rel="noopener noreferrer">Uudenmaan hinnasto</a><br/></>}
		{(alue === "hame" || !alue)  && <>{" > "}<a href="hinnasto_hame.pdf" target="_blank" rel="noopener noreferrer">Hämeen seudun hinnasto</a><br/></>}
		{(alue === "pirkanmaa" || !alue)  && <>{" > "}<a href="hinnasto_pirkanmaa.pdf" target="_blank" rel="noopener noreferrer">Pirkanmaan hinnasto</a></>}
		</div>
		<MySelect label="Tilaatko yksityisenä, yrittäjä vai taloyhtiönä?" name="customertype">
			<option value="1">Yksityisenä</option>
			<option value="2">Yrityksenä</option>
			<option value="3">Taloyhtiönä</option>
		</MySelect>
		<div>
		<Button
			type="button"
			onClick={props.navigateNext}
			disabled={!(props.values.customertype)}
		>
			Seuraava
		</Button>
		</div>
		</Column>
	</Row>
	</div>
);
}

export default AsiakastyyppiSivu;
