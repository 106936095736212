import React from 'react';
import { render } from 'react-dom';
import {
	BrowserRouter as Router,
	Switch,
	Route
} from "react-router-dom";
import Hintalaskuri from "./Hintalaskuri"
import Hiililaskuri from "./Hiililaskuri"
import AllekirjoitusSivu from './sivut/AllekirjoitusSivu';
import Success from './sivut/Success';
import VismaReturn from './sivut/VismaReturn';
import PasswordGuard from './sivut/PasswordGuard.js';
import LavaLomake from './LavaLomake';

import "./styles.css";
import "./styles-custom.css";

const App = () => (
	<Router>
		<Switch>
			<Route path="/hintalaskuri">
				<div className="app">
					<Hintalaskuri />
				</div>
			</Route>
			<Route path="/hiililaskuri">
				<div className="hiililaskuri">
					<PasswordGuard component={<Hiililaskuri />} />
				</div>
			</Route>
			<Route path="/sign/:orderId" render={({ match }) => (
				<div className="app">
					<AllekirjoitusSivu orderId={match.params.orderId} />
				</div>
			)} />
			<Route path="/success/:orderId" render={({ match }) => (
				<div className="app">
					<Success private_id={match.params.orderId} />
				</div>
			)} />
			<Route path="/vismareturn/:orderId" render={({ match }) => (
				<div className="app">
					<VismaReturn orderId={match.params.orderId} />
				</div>
			)} />
			<Route path="/">
				<div className="app">
					<LavaLomake />
				</div>
			</Route>
		</Switch>
	</Router>
);

render(<App />, document.getElementById('root'));

export default App;
