import React from 'react';
import {MySelect, MyTextInput} from '../Components';
import {Row, Column, Button} from '../util/Flex'

const YhteystietoSivu = props => (
	<div className="page">
	<Row>
	<Column>
	<h3>Tilaajan Yhteystiedot</h3>
	</Column>
	</Row>
	<Row>
	<Column>
		{props.values.customertype === "2" &&
		  <>
				<MyTextInput
					label="Yritys"
					name="company"
					type="text"
					placeholder="Yritys Oy"
				/>
				<MyTextInput
					label="Y-tunnus"
					name="companyid"
					type="text"
					placeholder="1234567-8"
				/>
			</>
	  }
		{props.values.customertype === "3" &&
			<>
				<MyTextInput
					label="Asukasyhtiön nimi"
					name="housingcompany_name"
					type="text"
					placeholder=""
				/>
				<MyTextInput
					label="Asukasyhtiön Y-tunnus"
					name="housingcompanycode"
					type="text"
					placeholder="1234567-8"
				/>
				<MyTextInput
					label="Isännöintitoimiston nimi"
					name="property_manager"
					type="text"
					placeholder=""
				/>
			</>
		}
		<MyTextInput
			label="Etu- ja Sukunimi"
			name="name"
			type="text"
			placeholder="Matti Meikäläinen"
		/>
		<MyTextInput
			label="Sähköpostiosoite"
			name="email"
			type="text"
			placeholder="m.meikalainen@esimerkki.fi"
		/>
		<MyTextInput
			label="Puhelinnumero"
			name="phone"
			type="text"
			placeholder="+3581234567"
		/>
		{props.values.customertype === "1" &&
			<>
				<MyTextInput
					label="Henkilötunnus"
					name="pin"
					type="text"
					placeholder="112233-1234"
				/>
				<div>henkilötunnusta käytetään tilaajan luottotietojen tarkastamiseen</div>
			</>
		}
		<MySelect label="Onko toimitukselle erillinen yhteyshenkilö?" name="separate_contactperson">
			<option value="0">Ei</option>
			<option value="1">Kyllä</option>
		</MySelect>
		</Column>
		<Column>
		{props.values.separate_contactperson === "1" &&
		<>
			<h3>Toimituksen yhteyshenkilön tiedot</h3>
			<MyTextInput
				label="Yhteyshenkilön nimi"
				name="contactperson_name"
				type="text"
				placeholder="Elli Esimerkki"
			/>
			<MyTextInput
				label="Yhteyshenkilön puhelinnumero"
				name="contactperson_phone"
				type="text"
				placeholder="+3581234567"
			/>
		</>
		}
		<h3>Toimitusosoite</h3>
		<MyTextInput
			label="Katuosoite"
			name="address"
			type="text"
			placeholder="Esimerkkikatu 6"
		/>
		<MyTextInput
			label="Postinumero"
			name="postalcode"
			type="text"
			placeholder="12345"
		/>
		<MyTextInput
			label="Postitoimipaikka"
			name="city"
			type="text"
			placeholder="Paikkakunta"
		/>
		<div>
		<Button
			type="button"
			onClick={props.navigateBack}
			disabled={props.pageIndex === 0}
		>
			Edellinen
		</Button>
		<Button
			type="button"
			onClick={props.navigateNext}
			disabled={props.errors.name || props.errors.phone || props.errors.email || props.errors.address || props.errors.postalcode || props.errors.city
                || (props.values.customertype === "2" && props.errors.company)
				|| (props.values.customertype === "2" && props.errors.companyid)
				|| (props.values.customertype === "3" && props.errors.housingcompany_name)
				|| (props.values.customertype === "3" && props.errors.housingcompanycode)
				|| (props.values.customertype === "3" && props.errors.property_manager)
				|| (props.values.customertype === "1" && props.errors.pin)
          	}
		>
			Seuraava
		</Button>
		</div>
		</Column>
		</Row>
	</div>
);

export default YhteystietoSivu;
