import React from 'react';
import {Row, Column} from '../util/Flex'

const VismaReturn = props => {

  const [signState, setSignState] = React.useState("");
  const [privateId, setPrivateId] = React.useState("");
  const [invitationLink, setInvitationLink] = React.useState("");

	const checkCompletion = () => {
		fetch('/api/orderstatus/' + props.orderId)
			.then(response => response.json())
			.then(data => {
				if (data.order_status === "signed") {
				  setPrivateId(data.private_id);
					setSignState("signed");
          fetch('/api/send_order/' + data.private_id)
				}
				if (data.order_status === "unsigned") {
				  setInvitationLink(data.invitation_link);
					setSignState("unsigned");
				}
		  });
	};

	React.useEffect(() => {
		checkCompletion();
	}, []);

	React.useEffect(() => {
		const timer = setInterval(() => {
				checkCompletion();
		}, 5000);
	}, []);

	return (
	<div id="page">
	<Row>
  	<Column>
    {signState === "unsigned" && !props.fromVisma &&
      <>
      	<h2>⚠️ Tilausta ei ole allekirjoitettu!</h2>
      	<div className='info'>
        Tilausta ei ole allekirjoitettu. Tämä voi johtua siitä, että antamasi henkilötunnus
				ei vastaa sähköisen allekirjoituksen henkilötunnusta.
      	</div>
			</>
			}
		{signState === "signed" &&
      <>
      	<h2>✅ Allekirjoitus onnistui!</h2>
      	<h3>
          Voit sulkea tämän sivun.
      	</h3>
			</>
			}
  	</Column>
	</Row>
	</div>
  )
};

export default VismaReturn;
