import styled from "@emotion/styled";
export const Container = styled.div`
  width: 100%;
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`;
export const Column = styled.div`
  max-width: 540px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 8px;
  @media screen and (min-width: 800px) {
    flex: 1;
  }
`;

export const Button = styled.button`
  display: inline;
  width: 160px;
`;
