import React from 'react';
import { MyCheckbox, MyTextInput } from '../Components';
import { Field } from "formik";
import { Column, Button } from '../util/Flex'

const LaskuSivu = props => {
	const invoiceOptions = { email: "Lasku sähköpostiin", ebank: "Sähköinen laskutus", shippingAddress: "Lasku toimitusosoitteeseen", invoiceAddress: "Lasku erilliseen osoitteeseen" };
	const [invoiceOption, setInvoiceOption] = React.useState(invoiceOptions.email)

	const clearInvoicingAddress = () => {
		props.setFieldValue('invoicing_address', '');
		props.setFieldValue('invoicing_postalcode', '');
		props.setFieldValue('invoicing_city', '');
	}
	const handleInvoiceOptionChange = (event) => {
		if (event.target.value === invoiceOptions.email) {
			clearInvoicingAddress();
			props.setFieldValue('custominvoice_enabled', '1');
			props.setFieldValue('custominvoice', 'Lasku sähköpostiin');
		}
		if (event.target.value === invoiceOptions.ebank) {
			clearInvoicingAddress();
			props.setFieldValue('custominvoice', '');
			props.setFieldValue('custominvoice_enabled', '1');
		}
		if (event.target.value === invoiceOptions.invoiceAddress) {
			props.setFieldValue('custominvoice_enabled', '1');
		}
		if (event.target.value === invoiceOptions.shippingAddress) {
			clearInvoicingAddress();
			props.setFieldValue('custominvoice', '');
			props.setFieldValue('custominvoice_enabled', '0');
		}
		setInvoiceOption(event.target.value);
	};

	React.useEffect(() => handleInvoiceOptionChange({ target: { value: invoiceOptions.email } }), []);


	return (
		<div className="page">
			<Column>
				<h3>Laskutustiedot</h3>
				<label>Haluatko laskun sähköpostiin, sähköisesti, toimitusosoitteeseen vai erilliseen osoitteeseen?</label>
				<select value={invoiceOption} onChange={handleInvoiceOptionChange}>
					{Object.keys(invoiceOptions).map(option => <option key={option} value={invoiceOptions[option]}>{invoiceOptions[option]}</option>)}
				</select>

				{invoiceOption === invoiceOptions.invoiceAddress &&
					<>
						<MyTextInput
							label="Katuosoite"
							name="invoicing_address"
							type="text"
							placeholder=""
						/>
						<MyTextInput
							label="Postinumero"
							name="invoicing_postalcode"
							type="text"
							placeholder=""
						/>
						<MyTextInput
							label="Kaupunki"
							name="invoicing_city"
							type="text"
							placeholder=""
						/>
					</>
				}
				{invoiceOption === invoiceOptions.ebank &&
					<>
						<label>Ohjeet sähköiseen laskutukseen</label>
						<Field as="textarea" name="custominvoice"></Field>
					</>
				}
				<label style={{fontSize: "1.6rem", marginTop: '18pt'}}>MUUT TIEDOT</label>
				<label>Vapaavalintaisia lisätietoja</label>
				<Field as="textarea" name="moreinfo"></Field>
				<MyTextInput
					style={props.values.discount_code?.toUpperCase() === "MESSUT TAMPERE" ? { backgroundColor: "var(--lightgreen)" } : {}}
					label="Kampanjakoodi"
					name="discount_code"
					type="text"
					placeholder=""
				/>
				{props.values.discount_code?.toUpperCase() === "MESSUT TAMPERE" && <div className="info stronger">Antamasi koodilla saat ilmaisen lavan toimituksen!</div>}
				<MyCheckbox name="acceptedTerms">
					<div>
						Hyväksyn Sorteran <a href="http://sortera.fi/tietosuojaseloste/" target="_blank" rel="noopener noreferrer">tietosuojakäytännön</a>{" "}ja{" "}
						<a href="http://sortera.fi/yleiset-toimitusehdot/" target="_blank" rel="noopener noreferrer">toimitusehdot</a>
					</div>
				</MyCheckbox>
				<div>
					<Button
						type="button"
						onClick={props.navigateBack}
						disabled={props.pageIndex === 0}
					>
						Edellinen
					</Button>
					<Button
						type="submit"
						disabled={!props.isValid}
					>
						Lähetä tilaus
					</Button>
				</div>

			</Column>
		</div>
	);
}

export default LaskuSivu;
